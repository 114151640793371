@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;500;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  padding: 0 !important;
  margin:0 !important;
  background-color: rgba(1, 22, 39, 1);
  font-family: 'Quicksand', sans-serif;
  letter-spacing: 0.01em;
  text-align: justify;
  font-size: clamp(0.8rem, 4vw, 1rem);
  line-height: 1.6;
  font-weight: 300 !important;
  color: rgba(222, 231, 231, 1);
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
}

a {
  color: rgba(222, 231, 231, 1);
  text-decoration: none;
  transition:color 0.5s ease-in-out;
}

a:link {
  text-decoration: none !important;
  color: rgba(222, 231, 231, 1);
}

a:hover {
  color: rgba(231, 29, 54, 1);
}

a:active {
  color: rgba(231, 29, 54, 1);
}

a:visited {
  color: rgba(231, 29, 54, 1);
}

/*************************** Container ***************************/
.width-container-home {
  margin-right: auto;
  margin-left: auto;
  max-width: 1000px;
  width: 100%;
}

.width-container {
  margin-right: auto;
  margin-left: auto;
  max-width: 1000px;
  padding-top: 50px;
  width: 100%;
}

/***************************** Center ****************************/
.center {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.center-v {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction:column;
          flex-direction:column;
}

/***************************** Margin ****************************/
.section-margin {
  margin-top: clamp(10rem, 10vw, 20rem) !important;
  margin-bottom: clamp(10rem, 10vw, 20rem) !important;
}

.section-bottom-margin {
  margin-bottom: clamp(9rem, 10vw, 18rem) !important;
}

/*************************** Selection ***************************/

::selection {
  color: rgba(242, 131, 145, 1);
}

/************************** Scroll-Bar ***************************/
::-webkit-scrollbar-track {
  color: rgba(1, 22, 39, 1);
}

::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar-thumb {
  border: 5px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: rgba(231, 29, 54, 1);
}

/************************** Background ***************************/
.bg-neutral {
  background: rgba(2, 27, 48, 1);
}

/***************************** Text ******************************/
.text-primary {
  color: rgba(231, 29, 54, 1) !important;
}

.text-secondary {
  color: rgba(149, 161, 179, 1) !important;
}

.text-tertiary {
  color: rgba(1, 22, 39, 1) !important;
}

.text-neutral {
  color: rgba(222, 231, 231, 1) !important;
}

/**************************** Border *****************************/
.border-t {
  border-top: 2px solid rgba(231, 29, 54, 1);
}

/**************************** NavBar *****************************/
.navigation {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  height: 60px;
  z-index: 100;
  -webkit-backdrop-filter: blur(1.2rem);
  backdrop-filter: blur(1.2rem);
  padding-left: 0;
  padding-right: 0;
}

.nav-logo {
  width: 40px;
  height: 40px;
}

.nav-menu {
  font-size: clamp(0.7rem, 3.4vw, 1.4rem);
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style: none;
  transition-timing-function: ease !important;
}

.nav-menu li {
  cursor: pointer;
}

.nav-menu li:last-child:before {
  width: 100%;
}

.nav-menu li:hover {
  color: rgba(231, 29, 54, 1) ;
}

.nav-menu li:after {
  margin-left: 2px;
  margin-right: 2px;
  content: "//";
  color: rgba(231, 29, 54, 1) ;
  cursor: default;
}

.nav-menu li:last-child:after {
  margin-left: 0;
  margin-right: 0;
  content: "" !important
}

.nav-menu span {
  text-decoration: none;
  margin:0;
  padding:0;
  border:0 none;
  position: relative;
  outline: none;
  z-index: 1;
}

.nav-menu span:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -2px;
  left: 0;
  background: rgba(231, 29, 54, 1);
  visibility: hidden;
  border-radius: 5px;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  transition: .25s linear;
}

.nav-menu span:hover:before, .nav-menu span:focus:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}

/**************************** Resume *****************************/
.resume-container {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 100;
}

.resume-fab {
  font-size: 0.8rem;
  font-weight: 500;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction:column;
          flex-direction:column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 65px;
  height: 65px;
  background: rgba(231, 29, 54, 1) !important;
  color: rgba(222, 231, 231, 1) !important;
  border-radius: 10px;
  -webkit-backdrop-filter: blur(1.2rem);
  backdrop-filter: blur(1.2rem);
  padding-left: 15px;
  padding-right: 15px;
  transition: box-shadow 0.3s ease-in-out;
}

.resume-fab:hover {
  box-shadow: 0 0 15px 1px rgba(231, 29, 54, 1);
}

.resume-image {
  height: 24px;
  width: 24px;
}

/*************************** Section *****************************/
.section-title {
  font-size: clamp(1.5rem, 3vw, 2.5rem);
  font-weight: 700;
  line-height: 1;
  text-transform:uppercase!important;
}

/***************************** Home ******************************/
.home-header {
  font-size: clamp(1.8rem, 8.8vw, 6.5rem);
  line-height: 1;
  font-weight: 700;
}

.home-footer {
  font-size: clamp(1.1rem, 2.5vw, 1.5rem);
  font-weight:500;
  text-align: start;
}

/***************************** About ******************************/
.about-perspective {
  -webkit-perspective: 1000px;
          perspective: 1000px;
}

.about-back {
  height: 300px;
  width: 250px;
  border-radius: 10px;
  background: url(/static/media/about_background2.b6843930.png)  center center no-repeat;
  background-size: 380px;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  transition: background 0.2s;
  transition-timing-function: ease !important;
}

.about-pic {
  height: 250px;
  width: 200px;
  border-radius: 10px;
  margin-top: 60px;
  background: url(/static/media/about_picture2.ba9b6fa2.png)  center center no-repeat;
  background-size: 200px;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-transform: translateZ(80px);
          transform: translateZ(80px);
  transition: background 0.2s;
  transition-timing-function: ease !important;
}

/*************************** Experience ***************************/
.experience-line {
  margin-top: 5px;
  position: absolute;
  border-left: 2px solid rgba(231, 29, 54, 1);
  height: 100% !important;
}

.experience-container {
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  transition-duration: 0.1s;
  transition-timing-function: ease !important;
  padding-bottom: 3rem;
}

.experience-container:last-child {
  padding-bottom: 0;
}

.experience-container:before {
  z-index: 1;
  content: "";
  width: 17px;
  height: 17px;
  background: rgba(1, 22, 39, 1);
  border: 2px solid rgba(231, 29, 54, 1);
  transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out;
  border-radius: 50%;
  position: absolute;
  left: -7.7px;
  top: 2px;
}

.experience-container:hover:before{
  background-color: rgba(222, 231, 231, 1);
  box-shadow:0 0 8px 2px rgba(231, 29, 54, 1);
}

.experience-bullet {
  line-height: 1.2;
  text-align: left;
  letter-spacing: 0;
  margin-top: 1rem;
  margin-bottom: 0;
}

.experience-bullet:before {
  content: "";
  width: 8px;
  height: 8px;
  background: rgba(1, 22, 39, 1);
  border: 2px solid rgba(231, 29, 54, 1);
  border-radius: 50%;
  position: absolute;
  left: -3px;
  margin-top: 5px;
}

/**************************** Projects ****************************/
.project-img-container {
  width: 150px;
  height: 150px;
  max-width: 150px;
  max-height: 150px;
  overflow: hidden;
  border-radius: 10px;
  background: rgba(231, 29, 54, 1);
}

.project-img {
  width: 140%;
  height: 140%;
  background-size: 140%;
  background-position: center;
  opacity: 0.5;
  transition: 0.2s ease-in-out, -webkit-transform 0.2s;
  transition: 0.2s ease-in-out, transform 0.2s;
  transition: 0.2s ease-in-out, transform 0.2s, -webkit-transform 0.2s;
}

.project-img:hover {
  opacity: 1;
  width: 110%;
  height: 110%;
  background-size: 110%;
}

.project-tech {
  position: relative;
  bottom: 0;
  left: 0;
}

/**************************** Contact ****************************/
.contact-card {
  position: relative;
  background: rgba(2, 27, 48, 1);
  box-shadow: 0 0 15px 1px rgba(0, 14, 26, 1);
  transition: box-shadow 0.3s ease-in-out;
  border-radius: 10px;
  height: 200px;
  min-height: 200px;
  overflow: hidden;
  z-index: 2;
}

.contact-card:hover {
  box-shadow: 10px 10px 20px 4px rgba(0, 14, 26, 1);
}

.contact-card-bg {
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  width: 120%;
  height: 120%;
  margin-top: 60px;
  margin-left: -40px;
  -webkit-filter: brightness(50);
          filter: brightness(50);
  opacity: 0.1;
  z-index: 1;

}

.contact-card-content {
  z-index: 2;
}

.contact-img {
  height: 30px;
  width: 30px;
}

.contact-at {
  height: 15px;
  width: 15px;
  margin-top: -5px;
}

.contact-at:before {
  display: block;
  content: ' ';
  background-image: url(/static/media/icon_at.3646a9d1.svg);
  background-size: 28px 28px;
  height: 28px;
  width: 28px;
}
/***************************** Tests ******************************/

/**************************** Shared ******************************/
.fw-500 {
  font-weight: 500;
}

.lh-0 {
  line-height: 0 !important;
}

.title {
  font-size: clamp(1rem, 4vw, 1.2rem);
  font-weight: 700;
  line-height: 1.1;
}

.sub-title {
  font-size: clamp(0.9rem, 4vw, 1.1rem);
  font-weight: 700;
  line-height: 1;
}

.tech-list {
  font-weight: 500;
  line-height: 1.2;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding: 0;
  list-style: none;
}

.tech-list li:after {
  margin-left: 5px;
  margin-right: 5px;
  content: "//";
  color: rgba(231, 29, 54, 1) ;
}

.tech-list li:last-child:after {
  margin-left: 0;
  margin-right: 0;
  content: "" !important
}

.anchor_underline {
  color: rgba(149, 161, 179, 1) !important;
  text-decoration: none;
  margin:0;
  padding:0;
  border:0 none;
  position: relative;
  outline: none;
  z-index: 1;
}

.anchor_underline:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: -2px;
  left: 0;
  background: rgba(149, 161, 179, 1);
  visibility: hidden;
  border-radius: 5px;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  transition: .25s linear;
}

.anchor_underline:hover:before, .anchor_underline:focus:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}
/************************** Screen-Size ***************************/

/* Extra Small devices (0px and up) */
@media (min-width: 0px) {

}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .project-tech {
    position: absolute !important;
    padding-left: 11px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

  .navigation {
    padding-left: 15px;
    padding-right: 15px;
  }

  .about-back {
    height: 450px;
    width: 350px;
    background-size: 550px;
    margin-top: -15px;
    margin-left: -20px;
  }

  .about-pic {
    height: 400px;
    width: 300px;
    background-size: 300px;
    margin-right: -80px;
  }

  .nav-menu li:after {
    margin-left: 10px;
    margin-right: 10px;
  }

  .tech-list li:after {
    margin-left: 10px;
    margin-right: 10px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}

